// startup.component.ts
import { Component, ViewChild, ViewContainerRef, ViewEncapsulation, AfterViewInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Observer, Subscription, filter, tap, Observable, map } from 'rxjs';
import { undoIcon, SVGIcon, arrowRotateCcwIcon} from '@progress/kendo-svg-icons';
import { RedemptionService } from 'src/app/services/redemption/redemption.service';
import { Store } from 'src/app/models/store';
import { Fkey} from 'src/app/models/fkeyResponse';
import { AppNotificationService } from 'src/app/services/notification/app-notification.service';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import {Roles} from 'src/app/enums/roles';
import { AccountService } from 'src/app/services/account/account.service';
import { QrImageService } from 'src/app/services/qrimage/qrimage.service';
import { StoreService } from 'src/app/services/store/store.service';

@Component({
  selector: 'app-setup',
  templateUrl: './setup.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./setup.component.scss']
})
export class SetupComponent implements AfterViewInit, OnDestroy {
  @ViewChild("notification", { read: ViewContainerRef })
  public appendContainer!: ViewContainerRef;
  public previewImage = '' 
  svgRefresh: SVGIcon = arrowRotateCcwIcon;
  svgUndo: SVGIcon = undoIcon;
  loading = false;
  allStores: Store[]  | undefined;
  selectedStore: Store | undefined;
  showStoreSelect = false;
  siteNumber : string | undefined;
  healthCheckResult: boolean | undefined;
  healthCheckSubscription: Subscription;
  loadingSubscription: Subscription | undefined;
  btnClass = "plain-background";
  qrimages$: Observable<string[]>;
  qrimages: string[] = []; 

  //CONSTRUCTOR
  constructor(public configService: ConfigurationService,
              private router: Router, 
              private notificationService: AppNotificationService,
              private redemption: RedemptionService,
              private account: AccountService,
              public qrImageService: QrImageService,
              public storeService: StoreService) {

              const storedValue = this.storeService.getCurrentStore();
              this.siteNumber = storedValue ? storedValue.storeNumber.toString() : undefined;
              redemption.resetHealthCheck();
              this.healthCheckSubscription = this.redemption.result$.pipe(filter(result => result !== undefined)).subscribe(result => {
                this.healthCheckResult = result;
                this.btnClass = (this.healthCheckResult) ? 'green-background' : 'red-background';
              });
              //auto load function key if admin user
              if(this.configService.configData?.FunctionKey===null)
                  this.updateFunctionKey();
              //get updated list of qa images
              this.qrimages$ = this.qrImageService.filenamesSubject.asObservable().pipe(
                map(filenames => {
                  this.qrimages = filenames;
                  return filenames;
                })
              );
  }

  
  ngAfterViewInit() {
    //subscribe to loading message
    this.loadingSubscription = this.redemption
    .getLoading()
    .subscribe((loading) => {
      this.loading = loading; //this doesn't use the show parameter 
    });
  }

  ngOnDestroy() {
    if(this.loadingSubscription !==  undefined) {
      this.loadingSubscription.unsubscribe();
    }
    if(this.healthCheckSubscription) {
      this.healthCheckSubscription.unsubscribe();
    }
  }

 healthCheck() {
    this.redemption.resetHealthCheck();
    this.redemption.healthCheck( this.siteNumber, true, true);
  }

  async storeSelect() {
    this.showStoreSelect=true;
    //load store data into array used for dropdown box if not already populated
    if(this.allStores !== undefined && this.allStores.length >= 1) {
      return;
    }
    const token = await this.account.getUserToken();
    if (typeof token === 'string') {
      this.notificationService.error(token, 5000, this.appendContainer);
      return;
    }
    this.redemption.getAllStores(token.accessToken).subscribe((stores) => {
      this.allStores = stores;
      if(this.allStores===undefined) {
        this.notificationService.error('Failed to Load Stores. Check internet connection.', 5000, this.appendContainer);
        this.showStoreSelect = false;
      } 
    })

  }

  storeSelectBack() : void {
    this.showStoreSelect = false;
  }

  selectStoreOK() {
    //save selection in cookie and navigate to scan page
    if(this.selectedStore?.storeNumber !== undefined) {
      this.siteNumber = this.selectedStore?.storeNumber.toString();
    }
    if (this.siteNumber !== undefined) {
      localStorage.setItem('CurrentStore',this.siteNumber);
      this.router.navigate(['/cardscan']);
    }
  }

  async updateFunctionKey() {
        const token = await this.account.getUserToken();
        if (typeof token === 'string') {
          this.notificationService.error(token, 5000, this.appendContainer);
          return;
        }
        //check for permission
        if(!token.roles.includes(Roles.Admin)) {
          return;
        }
        const observer: Observer<Fkey> = {
          next: (key: Fkey) => {
            localStorage.setItem('fkey', key.fkey.lock());
            if(this.configService.configData!==null) {
              this.configService.configData.FunctionKey = key.fkey;
              this.notificationService.info('Function Key Set', 5000, this.appendContainer);
            } else {
              this.notificationService.error('Configuration Data is null. Unable to set value.', 5000, this.appendContainer);
            }

          },
          error: (error: any) => {
            console.error('Failed to get function key:', error);
            if(error.message)
                this.notificationService.error(error.message, 5000, this.appendContainer);
            else if(error.error.message)
                this.notificationService.error(error.error.message, 5000, this.appendContainer);
            this.loading = false; 
          },
          complete: () => {
            this.loading = false; 
          }
        };
        
        this.redemption.getFunctionKey(this.siteNumber, token.accessToken).pipe(
          tap(() => {
            this.loading = true; 
          })
        ).subscribe(observer);
  }

  async updateImages() {
    await this.qrImageService.getImagesIfUpdated();
  }

  onImageSelect($event : any): void {
      this.qrImageService.getImageFromName($event).subscribe(imageDataUrl => {
      this.previewImage = imageDataUrl;
    });
  }


}