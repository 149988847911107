import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, filter, interval } from 'rxjs';
import { ScanStatus } from 'src/app/enums/scanstatus';
import { RedemptionService } from 'src/app/services/redemption/redemption.service';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import { OnlineStatusService } from 'src/app/services/online-status/online-status.service';
import { SwUpdate } from '@angular/service-worker';
import { QrImageService } from 'src/app/services/qrimage/qrimage.service';
import * as later from '@breejs/later';
import parseDuration from 'parse-duration';
import { StoreService } from 'src/app/services/store/store.service';

@Component({
  selector: 'app-cardscan',
  templateUrl: './cardscan.component.html',
  styleUrls: ['./cardscan.component.scss']
})
export class CardScanComponent implements OnInit,OnDestroy {
  //public variables
  scanStatus: ScanStatus = ScanStatus.scan;
  siteNumber: string | undefined;
  readonly ScanStatus : typeof ScanStatus = ScanStatus; 
  isLoading = false; //show loading animation if true
  isOnline = true; //screen shows green if true
  healthCheckResult: boolean | undefined;
  healthCheckSubscription: Subscription | undefined;
  public previewImage = ''//base64 encoded string for qrimage
  
  //private variables
  private inputBuffer: string[] = [];
  private clearBufferTimeout: any; 
  private showQrCodeScreenTimeout: any;
  private lastKeyPressTime: number | null = null;
  private loadingSubscription: Subscription | undefined = undefined;
  private onlineStatusSubscription: Subscription | undefined;
  private retryTimerSubscription: Subscription | undefined;
  private checkOnlineTimerSubscription: Subscription | undefined;
  private checkQrImageChanges: number | undefined;
  private versionUpdatesSubscription: Subscription | undefined;
  
  //convert timings to milliseconds and setup chron schedule
  private qrCheckSchedule = later.parse.cron(this.configService.configData?.QrCheckNewImageChron ?? "*/5 * * * *");
  private qrShowImage = parseDuration(this.configService.configData?.QrImageDisplay ?? '120 seconds') ?? 120*1000; //default 120
  private maxRetries = Number(this.configService.configData?.MaxRetries ?? '3');//default 3
  private healthCheckDelay = parseDuration(this.configService.configData?.HealthCheckDelay ?? '5 minutes') ?? 1000*5*60; //default health check 5 min
  private retryOfflineSync =  parseDuration(this.configService.configData?.RetryOfflineDelay ?? '2 minutes') ?? 1000*120; //default 2 min
  private showRedemptionResult = parseDuration('5s') ?? 5000; //default 5 sec
  private resetNumberInput = parseDuration('10s') ?? 10000; //default 10 sec

  constructor(private configService: ConfigurationService,
              private router: Router, 
              private redemption: RedemptionService,
              private onlineStatusService: OnlineStatusService,
              private swUpdate: SwUpdate,
              public qrImageService: QrImageService,
              public storeService: StoreService) {
      //get store number for storage or empty string if missing
      const currentStore = this.storeService.getCurrentStore();
      
      // healthCheckResult will change to reflect status
      redemption.healthCheck(currentStore?.storeNumber.toString(),false,false); //set initial status
      this.healthCheckSubscription = this.redemption.result$.pipe(filter(result => result !== undefined)).subscribe(result => {
        this.healthCheckResult = result;
      });
      
      //subscribe to offline queue retry
      console.debug(`Retry offline sync will trigger every ${this.retryOfflineSync / 1000 } seconds.`);
      this.retryTimerSubscription = interval(this.retryOfflineSync).subscribe(() => {
        this.redemption.sync().subscribe({
          complete: () => {
            console.debug('Sync completed.');
          }
        });
      });  

      //setup interval to run HealthCheck on schedule
      console.debug(`Retry online health check will trigger every ${this.healthCheckDelay / 1000 } seconds.`);
      this.checkOnlineTimerSubscription = interval(this.healthCheckDelay).subscribe(() => {
        if(this.isOnline) {
          this.redemption.healthCheck(this.siteNumber,false, false);
        }
      });  

      //setup check for new qrimages using javascript setinterval and later library, returns numeric ID for clearInterval
      console.debug(`Check for new images will occur next at ${later.schedule(this.qrCheckSchedule).next(1)}`);
      this.checkQrImageChanges = later.setInterval(() => {
        this.qrImageService.getImageFileinfo().then((fileInfoList) => {
          if(this.isOnline) {
            this.qrImageService.getImagesIfUpdated();
            console.debug(`Check for new images will occur next at ${later.schedule(this.qrCheckSchedule).next(1)}`);
          }
        }).catch((error) => {
          console.error('Error fetching new images', error);
        });
      }, this.qrCheckSchedule); 
  
      //setup PWA update checks
      if (this.swUpdate.isEnabled) {
        this.versionUpdatesSubscription = this.swUpdate.versionUpdates.subscribe((e) => {
          if(e.type==='VERSION_READY') {
            if(this.isLoading===false) {
              window.location.reload();
            } else {
              let retryCount = 0;
              const retryTimer = setInterval(() => {
                retryCount++;
                if (!this.isLoading) {
                  clearInterval(retryTimer);
                  window.location.reload();
                } else if (retryCount >= this.maxRetries) {
                  // If maximum retries reached, abort
                  clearInterval(retryTimer);
                  console.debug('Exceeded maximum retries. Aborting reload.');
                }
              }, 15000);//15s
            }
          }
        });
      }
  } //end constructor
  
  @HostListener('document:mousemove', ['$event'])
  @HostListener('document:click', ['$event'])
  handleMouseEvent(event: MouseEvent): void {
    if(this.scanStatus === ScanStatus.qrcode) {
      this.scanStatus = ScanStatus.scan;
      this.setupShowQrTimer();
    }

  }

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
     //ignore imput on status screens
     if(this.scanStatus === this.ScanStatus.error || this.scanStatus === this.ScanStatus.ok) {
        return;
     }
     if(this.scanStatus === ScanStatus.qrcode) {
      this.scanStatus = ScanStatus.scan;//reset
    }

     this.setupShowQrTimer();//start qr timer
     //only accept numbers
     if (/^\d$/.test(event.key)) {
       this.inputBuffer.push(event.key);
       this.lastKeyPressTime = Date.now();
       clearTimeout(this.clearBufferTimeout);
       this.setupKeypressTimer(); //start timers after each btn press
       console.debug("buffer:"+this.inputBuffer.join('') + ' len:'+ this.inputBuffer.length);
       if(this.inputBuffer.length===14 && this.siteNumber !== undefined) {
          clearTimeout(this.showQrCodeScreenTimeout);//stop qr image from showing
          this.redemption.creditBurn(this.inputBuffer.join(''),this.siteNumber).subscribe((success) => {
            if(success) {
              this.scanStatus=ScanStatus.ok;
            } else {
              this.scanStatus=ScanStatus.error;
            }
            // Set a timeout to reset the scanStatus after some time
            setTimeout(() => {
              this.scanStatus = ScanStatus.scan;
              this.inputBuffer=[];
              clearTimeout(this.clearBufferTimeout);
              this.setupShowQrTimer();//start timer again after return to scan screen
            }, this.showRedemptionResult); //show status screen time
          });
       }
     }

  }

  ngOnInit() {
    //subscribe to loading message
    this.loadingSubscription = this.redemption
    .getLoading().subscribe((loading) => {
      this.isLoading = loading;
    });
    this.onlineStatusSubscription = this.onlineStatusService.getOnlineStatus().subscribe((isOnline) => {
      this.isOnline = isOnline;
      if(isOnline) {
        this.redemption.sync().subscribe({
          complete: () => {
            console.debug('Sync completed.');
          }
        });
        this.redemption.healthCheck(this.siteNumber,false, false);
      }
    });
    //set timers to clear keyboard buffer and change screens
    this.setupKeypressTimer()
    this.setupShowQrTimer();
  }

  ngOnDestroy() {
    window.removeEventListener('keypress', this.handleKeyboardEvent);
    clearTimeout(this.clearBufferTimeout);
    clearTimeout(this.showQrCodeScreenTimeout)
    clearInterval(this.checkQrImageChanges);
    if(this.loadingSubscription !==  undefined) {
      this.loadingSubscription.unsubscribe();
    }
    if(this.onlineStatusSubscription) {
      this.onlineStatusSubscription.unsubscribe();
    }
    if(this.retryTimerSubscription) {
      this.retryTimerSubscription.unsubscribe();
    }
    if(this.versionUpdatesSubscription !== undefined) {
      this.versionUpdatesSubscription.unsubscribe();
    }
    if(this.checkOnlineTimerSubscription !== undefined) {
      this.checkOnlineTimerSubscription.unsubscribe();
    }
    if(this.healthCheckSubscription) {
      this.healthCheckSubscription.unsubscribe();
    }
    if(this.checkOnlineTimerSubscription) {
      this.checkOnlineTimerSubscription.unsubscribe();
    }
  }

  navigateSetup(): void {
    this.router.navigate(['/setup']); //go back to setup 
  }

  private setupKeypressTimer(): void {
    this.clearBufferTimeout = setTimeout(() => {
      const currentTime = Date.now();
      if (this.lastKeyPressTime && currentTime - this.lastKeyPressTime >= this.resetNumberInput) {
        this.inputBuffer = [];
        this.lastKeyPressTime = null;
        console.debug("Cleared keyboard buffer");
      }
    }, this.resetNumberInput);
  }

  //setup timeout to show qr screen
  private setupShowQrTimer(): void {
    if(this.showQrCodeScreenTimeout) {
      clearTimeout(this.showQrCodeScreenTimeout);
    }
    this.showQrCodeScreenTimeout = setTimeout(() => {
        this.qrImageService.getImageFileinfo().then((fileInfoList) => {
          if(fileInfoList.length>0) {
            this.qrImageService.getImageFromName(fileInfoList[0].name).subscribe(imageDataUrl => {
              console.debug('Set qrFilename = ',fileInfoList[0].name);
              this.previewImage = imageDataUrl;
              this.scanStatus = ScanStatus.qrcode;
            });
          }
        }).catch((error) => {
          console.error('Error fetching image filenames:', error);
        });
    }, this.qrShowImage); 
  }

}
