<div class="example-inner">
  <div class="k-loading-panel" *ngIf="loading">
    <div class="k-loading-panel-mask"></div>
    <div class="k-loading-panel-wrapper">
      <kendo-loader *ngIf="loading" [type]="'converging-spinner'" [themeColor]="'info'" [size]="'large'">
      </kendo-loader>
      <div class="k-loading-panel-text">
          <div *ngIf="showStoreSelect===false">Checking Connection...</div>
          <div *ngIf="showStoreSelect===true">Loading Store List...</div>
      </div>
    </div>  
  </div>
  <!--Current Location-->
  <div class="your-store">Current location:&nbsp;
    <div *ngIf="siteNumber===undefined; else displaySelectedStore">Not Selected</div>
    <ng-template #displaySelectedStore>Store# {{siteNumber}} </ng-template>
  </div>

  <!--image preview window-->
  <div class="image-preview" [ngStyle]="{'background-image': 'url('+previewImage+')'}"></div>

  <!--Select Location-->
  <div class="lookup-buttons" *ngIf="showStoreSelect===false">
    <button kendoButton title="StoreSelect" (click)="storeSelect()">Select Store</button>
    <button kendoButton title="HealthCheck" (click)="healthCheck()" [ngClass]="btnClass">Health Check</button>
    <button kendoButton title="UpdateFunctionKey" (click)="updateFunctionKey()"
            [style]="{'background-color': configService.configData?.FunctionKey === null ? 'red' : 'green', 'white-space': 'pre-wrap' }">
            {{ configService.configData?.FunctionKey === null ? 'No Function Key!\nLoad From Server' : 'Update FunctionKey'}}</button>
    <kendo-dropdownbutton class="dropdown-button" rounded="none" [data]="(qrimages$ | async) || []" title="UpdateImages" (itemClick)="onImageSelect($event)">QR Images</kendo-dropdownbutton>
    <button kendoButton class ="icon-button" rounded="none" [svgIcon]="svgRefresh" (click)="updateImages()"></button>
  </div>
  <div class="manual-lookup" *ngIf="showStoreSelect===true && allStores !== undefined">
    <h3 style="color: white;">Select Store Number</h3>
    <kendo-combobox [style.width]="'190px'"  [style.margin-right]="'4px'"
    [data]="allStores" textField="storeNumber"  
     [(ngModel)]="selectedStore" (valueChange)="storeService.changeStore($event)"
    valueField="storeNumber"></kendo-combobox>
    <button kendoButton (click)="storeSelectBack()" [svgIcon]="svgUndo"></button>
  </div>

  <!--Accept Current Selection-->
  <button kendoButton #selectStore class="select-store"
  [themeColor]="'success'" [disabled]="selectedStore===undefined && siteNumber===undefined" (click)="selectStoreOK()">OK</button>
</div>