import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Filesystem, Directory, ReaddirResult } from '@capacitor/filesystem';
import 'src/app/pipes/encrypt-decrypt';
import { FileInfo } from '@progress/kendo-angular-upload';
import { ImageInfo } from 'src/app/models/imageInfo'
import { BehaviorSubject, Observable, Subscription, catchError, firstValueFrom, from, map, of, switchMap, tap } from 'rxjs';
import { StoreService } from '../store/store.service';
import { Store } from 'src/app/models/store';

@Injectable({
  providedIn: 'root',
})
export class QrImageService implements OnDestroy {

    public filenamesSubject: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
    private readonly IMAGEINFO_KEY = 'imageInfo';
    private storeSubscription : Subscription;
    private currentStore = '';
    constructor(private http: HttpClient, private storeService: StoreService) {
      //when first run, setup store service and check for new images if folder is empty.
        this.storeSubscription = this.storeService.currentStore$.subscribe((store: Store) => {
            this.currentStore = store.storeNumber.toString();
            this.getImageFilenames().pipe(
              tap(filenames => {
                if (filenames.length === 0) {
                  console.log('No QR images found. Fetching images from server.');
                  this.getImageUrls();
                } 
              })
            ).subscribe();
        });  
    }
    
    ngOnDestroy(): void {
      if(this.storeSubscription) {
        this.storeSubscription.unsubscribe();
      }
    }

    getImageInfo(storeNumber: string) {
        return this.http.get<ImageInfo[]>(`api/getImageInfo?storeNumber=${storeNumber}`);
    }

    getImagesIfUpdated() {
        const localImageInfoJson = localStorage.getItem(`${this.IMAGEINFO_KEY}(${this.currentStore})`);
        const localImageInfo: ImageInfo[] = localImageInfoJson ? JSON.parse(localImageInfoJson) : [];
        console.debug('LocalImageInfo:',localImageInfo);
        this.getImageInfo(this.currentStore).subscribe({
            next: (serverImageInfo: ImageInfo[]) => {
                if (localImageInfo.length !== serverImageInfo.length) {
                    console.log('Number of images changed.');
                    this.getImageUrls();
                    return;
                }
                for (const serverImage of serverImageInfo) {
                    const localImage = localImageInfo.find(img => img.Name === serverImage.Name);
                    //if match is undefined, or contains don't match, then update images
                    if (localImage===undefined || localImage.LastModified !== serverImage.LastModified || 
                        localImage.ContentLength !== serverImage.ContentLength) {
                        console.log(`Updates detected for image: ${serverImage.Name}`);
                        this.getImageUrls();
                        return;
                    }
                }
                console.log('No changes detected, images are up to date.');
            },
            error: (error) => {
              console.error('Error fetching image info:', error);
            }
        });
    }

    getImageUrls() {
        this.http.get<string[]>(`api/getQrImages?storeNumber=${this.currentStore}`)
          .pipe(
            tap(urls => {
                if (urls.length > 0) {
                  this.downloadImageUrls(urls);
                } else {
                  console.debug(`No QR image URLs returned for store ${this.currentStore}`);
                }
              }),
              catchError((error: HttpErrorResponse) => {
                if (error.status === 404) {
                    console.debug(`No container found for store ${this.currentStore}`);
                } else {
                  console.error('Error fetching QR image URLs:', error);
                }
                return of([]);
              })
            )
            .subscribe(); 
    }

    async getImageFileinfo(): Promise<FileInfo[]> {
        try {
          const result = await Filesystem.readdir({
            path: this.currentStore,
            directory: Directory.Data,
          });
          return result.files;
        } catch (error) {
          console.error('Error reading local file system:', error);
          return [];
        }
      }
    
      getImageFromName(fileName: string): Observable<string> {
          let mimeType = '';
          return from(
              Filesystem.readFile({
                  path: `${this.currentStore}/${fileName}`,
                  directory: Directory.Data,
              })
          ).pipe(
              map(result => {
                  const extension = fileName.split('.').pop()?.toLowerCase();
                  switch (extension) {
                      case 'jpg':
                      case 'jpeg':
                        mimeType = 'image/jpeg';
                        break;
                      case 'png':
                        mimeType = 'image/png';
                        break;
                      case 'gif':
                        mimeType = 'image/gif';
                        break;
                      default:
                        console.warn('Unsupported file extension:', extension);
                        mimeType = 'application/octet-stream';
                    }
                  return `data:image/${mimeType};base64,${result.data}`;
              }),
              catchError((error) => {
                  console.error('Error reading file:', error);
                  return of('');
              })
          );
      }
        
      getImageFilenames(): Observable<string[]> {
        // Check if the folder exists, returning an observable
        return from(this.folderExists(this.currentStore)).pipe(
            switchMap(exists => {
                if (exists) {
                    return from(Filesystem.readdir({
                        path: this.currentStore,
                        directory: Directory.Data,
                    })).pipe(
                        map((result: ReaddirResult) => result.files.map(fileInfo => fileInfo.name)),
                        tap(filenames => this.filenamesSubject.next(filenames)), // Emit new subject
                        catchError(error => {
                            console.error('Error reading local file system:', error);
                            this.filenamesSubject.next([]);
                            return of([]);
                        })
                    );
                } else {
                    console.debug(`Folder ${this.currentStore} does not exist.`);
                    this.filenamesSubject.next([]);
                    return of([]);
                }
            }),
            catchError(error => {
                console.error('Error checking folder existence:', error);
                return of([]);
            })
        );
    }

    private async clearLocalFileSystem() {
      try {
        const result = await Filesystem.readdir({
          path: this.currentStore,
          directory: Directory.Data,
        });
  
        for (const file of result.files) {
            console.debug(`Attempting to delete file: ${this.currentStore}/${file.name}`);
          await Filesystem.deleteFile({
            path: `${this.currentStore}/${file.name}`,
            directory: Directory.Data,
          });
        }
        console.debug('Cleared local file system folder:', this.currentStore);
      } catch (error) {
        console.debug('Error clearing local file system:', error);
      }
    }
  
    private async downloadImageUrls(urls: string[]) {
      await this.clearLocalFileSystem(); // Clear folder before downloading
      console.debug('Downloading:',urls);
      for (const url of urls) {
        try {
          const response = await fetch(url);
          const blob = await response.blob();
          const reader = new FileReader();
          reader.readAsDataURL(blob); 
  
          reader.onloadend = async () => {
            const base64data = reader.result as string;
            const fullname = url.split('/').pop();
            if (!fullname) {
                console.error('Error: Unable to extract filename from URL:', url);
                return;
            }
            const fileName = fullname.split('?')[0];
            console.debug('Attempting to save file:',fileName);
            await Filesystem.writeFile({
              path: `${this.currentStore}/${fileName}`,
              data: base64data,
              directory: Directory.Data,
            });
  
            console.log(`Downloaded and stored: ${fileName}`);

            // Fetch and store image info in local storage
            const imageInfo = await firstValueFrom(this.getImageInfo(this.currentStore));
            localStorage.setItem(`${this.IMAGEINFO_KEY}(${this.currentStore})`, JSON.stringify(imageInfo));
            console.log('Image info stored in local storage.');


            await firstValueFrom(this.getImageFilenames());
          };
        } catch (error) {
          console.error('Error downloading image:', error);
        }
      }
     
    }

    private async folderExists(folderName: string): Promise<boolean> {
        try {
          const result = await Filesystem.stat({
            path: folderName,
            directory: Directory.Data,
          });
          return result.type === 'directory';
        } catch (error) {
            console.debug(`error checking if folder ${folderName} exists`,error);
            return false;
        }
      }
  

}
